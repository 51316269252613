<template>
    <div v-if="isLoading" class="loader bg-[#fff] rounded-[8px] p-2">
        <LoadingComponent />
    </div>

    <div v-else class="company-container bg-[#fff] rounded-[8px] p-2">
        <div class="navigation-tabs">
            <div class="nav-links">
                <router-link to="/companyprofile" :class="`${this.$route.path == '/companyprofile' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'list-check']" class="nav-icon" />
                    <span>Company profile</span>
                </router-link>

                <router-link to="/settings" :class="`${this.$route.path == '/userrofile' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Recruiter profile</span>
                </router-link>

                <router-link to="/InvitedCoworkerTab" :class="`${this.$route.path == '/InvitedCoworkerTab' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Invited Coworkers</span>
                </router-link>
            </div>
        </div>

        <div class="p-4 bg-gray-100 rounded-lg">
            <!-- Search Bar -->
            <div class="mb-4">
                <input type="text" placeholder="Search" class="w-full px-4 py-2 text-gray-500 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400" v-model="searchQuery" />
            </div>

            <!-- Table -->
            <div class="overflow-x-auto">
                <table class="min-w-full bg-white border rounded-lg">
                    <thead>
                        <tr class="text-left border-b">
                            <th class="p-4 text-gray-600">First Name</th>
                            <th class="p-4 text-gray-600">Last Name</th>
                            <th class="p-4 text-gray-600">Email</th>

                            <th class="p-4 text-gray-600">Date Invited</th>
                            <th class="p-4 text-gray-600">Role</th>
                            <th class="p-4 text-gray-600">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="project in filteredProjects" :key="project._id" class="border-b hover:bg-gray-50">
                            <td class="p-4">{{ project.first_name }}</td>
                            <td class="p-4">{{ project.last_name }}</td>
                            <td class="p-4">{{ project.email }}</td>
                            <td class="p-4">
                                {{ project.register ? new Date(project.register).toISOString().split("T")[0] : "N/A" }}
                            </td>
                            <td class="p-4">
                                {{ project.role == "owner" ? "Admin" : project.role ? project.role : "Colaborator" }}
                            </td>
                            <td class="p-4 text-center text-gray-500 cursor-pointer">
                                <!-- Edit Button -->
                                <button v-if="project.role !== 'owner' || project.role !== 'owner'" title="Edit" @click="showModal1 = true" class="text-blue-500 hover:text-blue-700 mx-1">
                                    <i class="fas fa-edit"></i>
                                    <!-- Font Awesome edit icon -->
                                </button>

                                <!-- Modal -->
                                <div v-if="showModal1" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                    <div class="bg-white w-96 p-6 rounded-lg shadow-lg relative">
                                        <h2 class="text-lg font-semibold text-gray-800 mb-2">Edit candidate company role</h2>
                                        <div class="text-base w-1/4 font-medium px-3 mb-2">
                                            <InputList firstItem="Role" :items="['Admin', 'Colaborator']" width="200%" height="100%" />
                                        </div>

                                        <!-- Buttons -->
                                        <div class="flex justify-end space-x-4">
                                            <button @click="cancelDelete" class="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400">Cancel</button>
                                            <button @click="confirmEdit" class="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">Confirm</button>
                                        </div>

                                        <!-- Close Button (optional) -->
                                        <button @click="showModal1 = false" class="absolute top-2 right-2 text-gray-500 hover:text-gray-700">&times;</button>
                                    </div>
                                </div>

                                <!-- Delete Button -->
                                <button v-if="project.role !== 'owner' || project.role !== 'owner'" title="Delete" @click="showModal = true" class="text-red-500 hover:text-red-700 mx-1">
                                    <i class="fas fa-trash"></i>
                                    <!-- Font Awesome delete icon -->
                                </button>

                                <!-- Modal -->
                                <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                    <div class="bg-white w-96 p-6 rounded-lg shadow-lg relative">
                                        <h2 class="text-lg font-semibold text-gray-800 mb-4">Confirm Delete</h2>
                                        <p class="text-gray-600 mb-6">Are you sure you want to delete this teamate?</p>

                                        <!-- Buttons -->
                                        <div class="flex justify-end space-x-4">
                                            <button @click="cancelDelete" class="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400">Cancel</button>
                                            <button @click="confirmDelete(project._id, project.role)" class="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">Confirm</button>
                                        </div>

                                        <!-- Close Button (optional) -->
                                        <button @click="showModal = false" class="absolute top-2 right-2 text-gray-500 hover:text-gray-700">&times;</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { useStore } from "../store/index";

import InputList from "../components/ReusableComponents/ListInput.vue";

export default {
    components: { InputList },

    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            showDeletePopup: false,
            showModal: false,
            showModal1: false,
            searchQuery: "",
            activeTab: "all",
            projects1: [],
            showPopup: false,
            selectedOption: "",
            options: ["Admin", "Colaborator"],
        };
    },
    async mounted() {
        console.log("Mounted hook is running");
        this.fetchTeammates();

        /*
               await axios
                   .request(config)
                   .then((response) => {
                       this.message = response.data.message;
                   })
                   .catch((error) => {
                      
                       console.log(error);
                   });*/
    },

    computed: {
        filteredProjects() {
            return this.projects1.filter((project) => project.last_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
        },
    },

    methods: {
        confirmDelete(userID, role) {
            // Add your deletion logic here
            console.log("Item deleted.");
            this.deleteTeamate(userID, role);
            this.showModal = false; // Close modal after confirmation
        },

        confirmEdit() {
            // Add your deletion logic here
            this.showModal1 = false; // Close modal after confirmation
        },
        cancelDelete() {
            this.showModal = false; // Close modal without action
            this.showModal1 = false; // Close modal after confirmation
        },

        deleteTeamate(userID, role) {
            if (role != "Admin" || role != "owner") {
                let data = JSON.stringify({
                    idU: userID,
                    CompanyName: this.Store.company_name,
                });

                let config = {
                    method: "put",
                    maxBodyLength: Infinity,
                    url: `https://server.go-platform.com/company/DeleteTeamates`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };
                axios
                    .request(config)
                    .then((res) => {
                        console.log(res);
                        this.$router.go();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },

        async fetchTeammates() {
            try {
                let data = JSON.stringify({
                    company_name: this.Store?.company_name || "Test Company", // Fallback to a test value
                });

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://server.go-platform.com/company/fetchTeamates",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };

                const response = await axios.request(config);

                // Log response data if successful
                this.message = response.data.message;
                console.log("THESE ARE OUR TEAMMATES");

                console.log(response.data);
                this.projects1 = response.data.recruiters;
                for (var i = 0; i < this.projects1.length; i++) this.projects1[i].register = this.projects1[i].register.toISOString().split("T")[0];

                console.log(this.projects1);

                console.log("THESE ARE OUR TEAMMATES");
            } catch (error) {
                console.error("Request failed:", error);
                console.error("Error details:", error.response?.data);
            }
        },
        setActiveTab(tab) {
            this.activeTab = tab;
        },
    },
};
</script>

<style scoped>
/* Scoped styles are not needed as Tailwind handles most styling. */

.company-container {
    padding: 24px 8px;
}
.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 32px;
    padding-left: 48px;
    border-bottom: 2px solid #edeff2;
    margin-bottom: 25px;
}
.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}
.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}
</style>
